import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FormControl, InputLabel, MenuItem, Select, TextField, Button } from '@mui/material';
import './ChangePlanPage.scss';
import { changePlan } from '../../data/api';
import { Bounce, ToastContainer, toast } from 'react-toastify';

const ChangePlanPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const query = new URLSearchParams(location.search);
  const currentPlan = query.get('from');
  const newPlan = query.get('to');
  const [accountType, setAccountType] = useState('');
  const [showCompanyField, setShowCompanyField] = useState(false);
  const [showTaxIdField, setShowTaxIdField] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    lastName: '',
    companyName: '',
    userType: '',
    taxId: '',
    street: '',
    number: '',
    phone: '',
    country: '',
    province: '',
    postalCode: '',
    ivaCondition: '',
    plan: newPlan,
  });

  // Mapa de identificadores fiscales por país
  const taxIdMap = {
    Argentina: 'CUIL/CUIT',
    México: 'RFC',
    Colombia: 'NIT',
    Perú: 'RUC',
    Chile: 'RUT',
    Ecuador: 'RUC',
    Uruguay: 'RUT',
    Paraguay: 'RUC',
    Bolivia: 'NIT',
  };

  const handleAccountTypeChange = (e) => {
    const selectedType = e.target.value;
    setAccountType(selectedType);
    setShowCompanyField(selectedType === 'empresa');
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    console.log(formData);
  };

  const plans = {
    free: {
      title: 'Plan Free',
      description: ['1 equipos máximo', '100 MB de almacenamiento de datos', '2 widgets máximo'],
      price: 'Gratis',
    },
    pro: {
      title: 'Plan Pro',
      description: ['3 equipos máximo', '500 MB de almacenamiento de datos', '5 widgets máximo'],
      price: '$15 USD/mes',
    },
    full: {
      title: 'Plan Full',
      description: ['Equipos ilimitados', '10 GB de almacenamiento de datos', 'Widgets ilimitados'],
      price: '$30 USD/mes',
    },
  };

  const isBillingRequired = currentPlan === 'free' && (newPlan === 'pro' || newPlan === 'full');
  const showClarification = (currentPlan === 'pro' && newPlan === 'full') || (currentPlan === 'full' && newPlan === 'pro');

  const handleConfirm = async () => {
    // Validar campos
    const requiredFields = ['name', 'lastName', 'userType', 'street', 'number', 'phone', 'country', 'postalCode'];
    if (accountType === 'empresa') {
      requiredFields.push('companyName', 'taxId');
    }
    if (formData.country === 'Argentina') {
      requiredFields.push('province');
      requiredFields.push('ivaCondition');
    }

    const isValid = requiredFields.every(field => formData[field]);

    if (!isValid && currentPlan == 'free') {
      toast.error('Por favor, completa todos los campos obligatorios.', {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "dark",
        transition: Bounce,
      });
      return;
    }
    console.log('Cambio de plan confirmado:', { currentPlan, newPlan, formData });
    const resp = await changePlan(newPlan, formData)
    console.log(resp);
    if(resp.status === 'success'){
      if(newPlan == 'pro'){
        // Redirect to external link for payment processing
        window.location.href = `${process.env.REACT_APP_DLOCAL_URL_PRO}?email=${localStorage.getItem('email')}`; // Replace with appropriate link
      }
      if(newPlan == 'full'){
        // Redirect to external link for payment processing
        window.location.href = `${process.env.REACT_APP_DLOCAL_URL_FULL}?email=${localStorage.getItem('email')}`; // Replace with appropriate link
      }
      if(newPlan == 'free'){
        window.location.href = '/dashboards'
      }
    }
    
    //navigate('/'); // Redirige a la página principal o donde desees
  };

  const handleCountryChange = (e) => {
    const selectedCountry = e.target.value;
    setFormData({
      ...formData,
      country: selectedCountry,
    });
    setShowTaxIdField(!!taxIdMap[selectedCountry]);
  };

  return (
    <div className="change-plan-page">
      <h1 className="page-title">Cambio de Plan</h1>
      <div className="plan-details">
        <div className="current-plan">
          <h2>Plan Actual: {plans[currentPlan].title}</h2>
          <ul>
            {plans[currentPlan].description.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        </div>

        {/* Flecha de migración */}
        <div className="migration-arrow">→</div>

        <div className="new-plan">
          <h2>Nuevo Plan: {plans[newPlan].title}</h2>
          <ul>
            {plans[newPlan].description.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        </div>
      </div>

      {isBillingRequired && (
        <div className="billing-info">
          <h2>Información de Facturación</h2>
          <div className="form-group">
            <TextField label="Nombres" name="name" onChange={handleInputChange} required />
            <TextField label="Apellidos" name="lastName" onChange={handleInputChange} required />
          </div>

          <FormControl fullWidth required>
            <InputLabel>Tipo de cuenta</InputLabel>
            <Select name="userType" value={accountType} onChange={handleAccountTypeChange}>
              <MenuItem value="" disabled>Selecciona un tipo</MenuItem>
              <MenuItem value="individual">Individual</MenuItem>
              <MenuItem value="empresa">Empresarial</MenuItem>
            </Select>
          </FormControl>

          {showCompanyField && (
            <TextField label="Nombre de la Empresa" name="companyName" onChange={handleInputChange} required />
          )}

          <FormControl fullWidth required>
            <InputLabel>País</InputLabel>
            <Select value={formData.country} onChange={handleCountryChange}>
              {Object.keys(taxIdMap).map((country) => (
                <MenuItem key={country} value={country}>{country}</MenuItem>
              ))}
            </Select>
          </FormControl>

          {formData.country === 'Argentina' && (
            <FormControl fullWidth>
              <InputLabel>Provincia</InputLabel>
              <Select
                value={formData.province}
                name="province"
                onChange={handleInputChange}
                required
              >
                <MenuItem value="Buenos Aires">Buenos Aires</MenuItem>
                <MenuItem value="Ciudad Autónoma de Buenos Aires">Ciudad Autónoma de Buenos Aires</MenuItem>
                <MenuItem value="Catamarca">Catamarca</MenuItem>
                <MenuItem value="Chaco">Chaco</MenuItem>
                <MenuItem value="Chubut">Chubut</MenuItem>
                <MenuItem value="Córdoba">Córdoba</MenuItem>
                <MenuItem value="Corrientes">Corrientes</MenuItem>
                <MenuItem value="Entre Ríos">Entre Ríos</MenuItem>
                <MenuItem value="Formosa">Formosa</MenuItem>
                <MenuItem value="Jujuy">Jujuy</MenuItem>
                <MenuItem value="La Pampa">La Pampa</MenuItem>
                <MenuItem value="La Rioja">La Rioja</MenuItem>
                <MenuItem value="Mendoza">Mendoza</MenuItem>
                <MenuItem value="Misiones">Misiones</MenuItem>
                <MenuItem value="Neuquén">Neuquén</MenuItem>
                <MenuItem value="Río Negro">Río Negro</MenuItem>
                <MenuItem value="Salta">Salta</MenuItem>
                <MenuItem value="San Juan">San Juan</MenuItem>
                <MenuItem value="San Luis">San Luis</MenuItem>
                <MenuItem value="Santa Cruz">Santa Cruz</MenuItem>
                <MenuItem value="Santa Fe">Santa Fe</MenuItem>
                <MenuItem value="Santiago del Estero">Santiago del Estero</MenuItem>
                <MenuItem value="Tierra del Fuego">Tierra del Fuego</MenuItem>
                <MenuItem value="Tucumán">Tucumán</MenuItem>
              </Select>
            </FormControl>
          )}

          <div className="form-group">
            <TextField label="Código Postal" name="postalCode" onChange={handleInputChange} required />
            <TextField label="Calle" name="street" onChange={handleInputChange} required />
            <TextField label="Número" name="number" type="number" onChange={handleInputChange} required />
            <TextField label="Teléfono" name="phone" type="number" onChange={handleInputChange} required />
          </div>

          {showTaxIdField && (
            <TextField
              label={taxIdMap[formData.country]}
              name="taxId"
              onChange={handleInputChange}
              required
            />
          )}

          {formData.country === 'Argentina' && (
            <FormControl fullWidth required>
              <InputLabel>Condición IVA</InputLabel>
              <Select value={formData.ivaCondition} name="ivaCondition" onChange={handleInputChange}>
                <MenuItem value="Responsable Inscripto">Responsable Inscripto</MenuItem>
                <MenuItem value="Monotributista">Monotributista</MenuItem>
                <MenuItem value="Consumidor Final">Consumidor Final</MenuItem>
                <MenuItem value="Exento">Exento</MenuItem>
              </Select>
            </FormControl>
          )}
        </div>
      )}
      {showClarification && (
        <p className="clarification-message">
          Al cambiar de plan, serás redirigido a una página para ejecutar el primer cobro mensual. Esta fecha será tu nueva fecha de cobro cada mes.
        </p>
      )}
      <div className="buttons">
        <Button variant="contained" color="primary" onClick={handleConfirm}>Confirmar</Button>
        <Button variant="contained" color="warning" onClick={() => navigate(-1)}>Cancelar</Button>
      </div>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover={false}
        theme="dark"
        transition={Bounce}
      />
    </div>
  );
};

export default ChangePlanPage;
