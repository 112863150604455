import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import './RegisterPaidPage.scss';
import { Logo } from '../../Components/Logo/Logo';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { Bounce, ToastContainer, toast } from 'react-toastify';
import { registerPaidUser } from '../../data/api';

export const RegisterPaidPage = () => {
  const { plan } = useParams();
  const [accountType, setAccountType] = useState('');
  const [showCompanyField, setShowCompanyField] = useState(false);
  const [showTaxIdField, setShowTaxIdField] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    lastName: '',
    email: '',
    password: '',
    companyName: '',
    userType: '',
    taxId: '', // Generic field for tax identifier
    street: '',
    number: '',
    phone: '',
    country: '',
    province: '',
    postalCode: '',
    ivaCondition: '',
    plan: plan
  });

  // Map of tax identifiers by country
  const taxIdMap = {
    Argentina: 'CUIL/CUIT',
    México: 'RFC',
    Colombia: 'NIT',
    Perú: 'RUC',
    Chile: 'RUT',
    Ecuador: 'RUC',
    Uruguay: 'RUT',
    Paraguay: 'RUC',
    Bolivia: 'NIT',
  };

  const handleAccountTypeChange = (e) => {
    const selectedType = e.target.value;
    setAccountType(selectedType);
    setShowCompanyField(selectedType === 'empresa');
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    console.log(formData);
    
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (formData.password.length < 6) {
      toast.error('La contraseña debe tener al menos 6 caracteres', {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "dark",
        transition: Bounce,
      });
      return;
    }

    // Simulated server request
    setIsLoading(true);

    const resp = await registerPaidUser(formData);

    if (resp.status === 'success') {
      // Successful registration
      localStorage.setItem('token', resp.token);
      toast.success('Registro completado con éxito', {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "dark",
        transition: Bounce,
      });
    } else {
      // Registration error
      toast.error(resp.error, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "dark",
        transition: Bounce,
      });
      setIsLoading(false);
      return
    }

    setIsLoading(false);
    // Save data to localStorage
    localStorage.setItem('data', JSON.stringify(formData));
    localStorage.setItem('token', resp.token);
    localStorage.setItem('email', resp.email);
    localStorage.setItem('userId', resp.userId);
    if(plan == 'pro'){
      // Redirect to external link for payment processing
      window.location.href = `${process.env.REACT_APP_DLOCAL_URL_PRO}?email=${formData.email}`; // Replace with appropriate link
    }
    if(plan == 'full'){
      // Redirect to external link for payment processing
      window.location.href = `${process.env.REACT_APP_DLOCAL_URL_FULL}?email=${formData.email}`; // Replace with appropriate link
    }
  };

  const handleCountryChange = (e) => {
    const selectedCountry = e.target.value;
    setFormData({
      ...formData,
      country: selectedCountry,
    });
    // Show tax ID field only if the country is in the map
    setShowTaxIdField(!!taxIdMap[selectedCountry]);
  };

  useEffect(() => {
    
    if( !(plan == 'pro' || plan == 'full') ){
      window.location.href = '/register';
    }

  }, [])
  

  return (
    <div className="register-container">
      <div className="register-box">
        <Logo />
        <h2 className="plan-type">Plan {plan == 'pro' ? 'Pro' : 'Full'}</h2>

        <form className="register-form" onSubmit={handleSubmit}>
          <h3>Completa tu registro</h3>
          <input type="text" name="name" placeholder="Nombres" onChange={handleInputChange} required />
          <input type="text" name="lastName" placeholder="Apellidos" onChange={handleInputChange} required />
          <input type="email" name="email" placeholder="Email" onChange={handleInputChange} required />
          <input type="password" name="password" placeholder="Contraseña" onChange={handleInputChange} required />
          
          <select name="userType" value={accountType} onChange={handleAccountTypeChange} required>
            <option value="" disabled>Tipo de cuenta</option>
            <option value="individual">Individual</option>
            <option value="empresa">Empresarial</option>
          </select>

          {showCompanyField && (
            <input type="text" name="companyName" placeholder="Nombre empresa" onChange={handleInputChange} required />
          )}

          <FormControl fullWidth>
            <InputLabel>País</InputLabel>
            <Select value={formData.country} onChange={handleCountryChange} required>
              {Object.keys(taxIdMap).map((country) => (
                <MenuItem key={country} value={country}>{country}</MenuItem>
              ))}
            </Select>
          </FormControl>

          {formData.country === 'Argentina' && (
            <FormControl fullWidth>
              <InputLabel>Provincia</InputLabel>
              <Select
                value={formData.province}
                name="province"
                onChange={handleInputChange}
                required
                sx={{marginTop: '10px'}}
              >
                <MenuItem value="Buenos Aires">Buenos Aires</MenuItem>
                <MenuItem value="Ciudad Autónoma de Buenos Aires">Ciudad Autónoma de Buenos Aires</MenuItem>
                <MenuItem value="Catamarca">Catamarca</MenuItem>
                <MenuItem value="Chaco">Chaco</MenuItem>
                <MenuItem value="Chubut">Chubut</MenuItem>
                <MenuItem value="Córdoba">Córdoba</MenuItem>
                <MenuItem value="Corrientes">Corrientes</MenuItem>
                <MenuItem value="Entre Ríos">Entre Ríos</MenuItem>
                <MenuItem value="Formosa">Formosa</MenuItem>
                <MenuItem value="Jujuy">Jujuy</MenuItem>
                <MenuItem value="La Pampa">La Pampa</MenuItem>
                <MenuItem value="La Rioja">La Rioja</MenuItem>
                <MenuItem value="Mendoza">Mendoza</MenuItem>
                <MenuItem value="Misiones">Misiones</MenuItem>
                <MenuItem value="Neuquén">Neuquén</MenuItem>
                <MenuItem value="Río Negro">Río Negro</MenuItem>
                <MenuItem value="Salta">Salta</MenuItem>
                <MenuItem value="San Juan">San Juan</MenuItem>
                <MenuItem value="San Luis">San Luis</MenuItem>
                <MenuItem value="Santa Cruz">Santa Cruz</MenuItem>
                <MenuItem value="Santa Fe">Santa Fe</MenuItem>
                <MenuItem value="Santiago del Estero">Santiago del Estero</MenuItem>
                <MenuItem value="Tierra del Fuego">Tierra del Fuego</MenuItem>
                <MenuItem value="Tucumán">Tucumán</MenuItem>
              </Select>
            </FormControl>
          )}
          <input type="text" name="postalCode" placeholder="Código postal" onChange={handleInputChange} required />
          <input type="text" name="street" placeholder="Calle" onChange={handleInputChange} required />
          <input type="number" name="number" placeholder="Número de calle" onChange={handleInputChange} required />
          <input type="number" name="phone" placeholder="Teléfono" onChange={handleInputChange} required />

          {/* Show tax ID field based on the selected country */}
          {showTaxIdField && (
            <input
              type="text"
              name="taxId"
              placeholder={taxIdMap[formData.country]}
              onChange={handleInputChange}
              required
            />
          )}

          {/* Additional field for IVA condition if the country is Argentina */}
          {formData.country === 'Argentina' && (
            <FormControl fullWidth>
              <InputLabel>Condición IVA</InputLabel>
              <Select value={formData.ivaCondition} name="ivaCondition" onChange={handleInputChange} required>
                <MenuItem value="Responsable Inscripto">Responsable Inscripto</MenuItem>
                <MenuItem value="Monotributista">Monotributista</MenuItem>
                <MenuItem value="Consumidor Final">Consumidor Final</MenuItem>
                <MenuItem value="Exento">Exento</MenuItem>
              </Select>
            </FormControl>
          )}

          <div className="form-navigation">
            <button type="submit" className="submit-btn">Ir a pagar</button>
          </div>
          <p>Al continuar, serás redirigido a una página externa para completar el pago de la suscripción.</p>
        </form>
      </div>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover={false}
        theme="dark"
        transition={Bounce}
      />
    </div>
  );
};
