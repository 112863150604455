import React from 'react';
import './TermsPage.scss';

export const TermsPage = () => {
  return (
    <div className="terms-page">
      <header className="terms-header">
        <h1>Términos y Condiciones</h1>
      </header>

      <section className="terms-content">
        <h2>Bienvenido a IOTERIS</h2>
        <p>
          Estos Términos y Condiciones rigen el uso de la plataforma IOTERIS, disponible en www.ioteris.com.ar. Al utilizar nuestros servicios, aceptas los siguientes términos. Si no estás de acuerdo con alguna de estas condiciones, te pedimos que no uses la plataforma.
        </p>

        <h3>1. Uso de la Plataforma</h3>
        <p>
          IOTERIS te permite conectar y monitorear dispositivos IoT en tiempo real. El acceso y uso de la plataforma está limitado a los fines descritos en nuestra documentación y en los planes de suscripción correspondientes.
        </p>

        <h3>2. Cuentas y Seguridad</h3>
        <p>
          Para usar nuestros servicios, debes crear una cuenta. Eres responsable de mantener la seguridad de tu cuenta y contraseñas. IOTERIS no será responsable de ningún daño causado por el mal uso de tu cuenta.
        </p>

        <h3>3. Precios y Pagos</h3>
        <p>
          Los precios de nuestros planes son los indicados en la página de precios. El pago de las suscripciones se realiza de forma mensual, y puedes cancelar tu suscripción en cualquier momento a través de tu cuenta.
        </p>

        <h3>4. Uso Aceptable</h3>
        <p>
          No se permite el uso de IOTERIS para actividades ilegales o que infrinjan los derechos de terceros. Nos reservamos el derecho de suspender o cancelar cuentas que infrinjan estos términos.
        </p>

        <h3>5. Responsabilidad</h3>
        <p>
          IOTERIS no se responsabiliza por pérdidas de datos, daños a dispositivos o cualquier otro problema técnico derivado del uso de la plataforma. El usuario es responsable de asegurar la integridad y seguridad de sus propios dispositivos y datos.
        </p>

        <h3>6. Modificaciones</h3>
        <p>
          IOTERIS se reserva el derecho de modificar estos Términos y Condiciones en cualquier momento. Cualquier cambio será notificado a través de nuestra plataforma y estará vigente una vez publicado.
        </p>

        <h3>7. Contacto</h3>
        <p>
          Si tienes alguna pregunta sobre estos Términos y Condiciones, puedes ponerte en contacto con nosotros en contacto@ioteris.com.ar.
        </p>
      </section>
    </div>
  );
}
