import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import './App.scss';
import { NavBar } from './Components/NavBar/NavBar';
import { HomeContainer } from './Components/HomeContainer/HomeContainer';
import { HomePage } from './Pages/HomePage/HomePage';
import { LoginPage } from './Pages/LoginPage/LoginPage';
import { RegisterPage } from './Pages/RegisterPage/RegisterPage';
import { RegisterFreePage } from './Pages/RegisterFreePage/RegisterFreePage';
import { RegisterPaidPage } from './Pages/RegisterPaidPage/RegisterPaidPage';
import { SuccessPaymentPage } from './Pages/SuccessPaymentPage/SuccessPaymentPage';
import ChangePlanPage from './Pages/ChangePlanPage/ChangePlanPage';
import { Analytics } from "@vercel/analytics/react"
import { ForgotPassword } from './Pages/ForgotPassword/ForgotPassword';
import { TermsPage } from './Pages/TermsPage/TermsPage';

function App() {
  return (
    <BrowserRouter>
      <div className="home-container">

      <Analytics/>

        <div className="pages">
          <Routes>
              <Route exact path="/" element={<HomePage />}/>
              <Route exact path="/terms" element={<TermsPage />}/>
              <Route exact path="/forgot-password" element={<ForgotPassword />}/>
              <Route exact path="/change-plan" element={<ChangePlanPage />}/>
              <Route exact path="/login" element={<LoginPage />}/>
              <Route exact path="/success" element={<SuccessPaymentPage />}/>
              <Route exact path="/register" element={<RegisterPage />}/>
              <Route exact path="/register/free" element={<RegisterFreePage />}/>
              <Route exact path="/register/:plan" element={<RegisterPaidPage />}/>
              <Route exact path="/dashboards" element={<HomeContainer path="/dashboards"/>}/>
              <Route exact path="/devices" element={<HomeContainer path="/devices"/>}/>
              <Route exact path="/settings" element={<HomeContainer path="/settings"/>}/>
              <Route exact path="/docs" element={<HomeContainer path="/docs"/>}/>
              <Route exact path="*" element={<Navigate to="/dashboards"/>}/>
          </Routes>
        </div>
      </div>

    </BrowserRouter>
  );
}

export default App;
